import { useEffect, useState } from 'react';
import { fetchAndStoreUserData } from '../../config/utils/fetchAndStoreUserData';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { SaveStorage } from '../../config/utils/Storage';

interface Props {
    children: any;
}

const IdleTimer = ({ children }: Props) => {
    const hoursToDelayDefault = 6 * 60 * 60 * 1000; // every 6 hours
    // const hoursToDelayDefault = 5000;
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [hoursToDelay, setHoursToDelay] = useState<number | null>(null);
    const dispatch = useAppDispatch();

    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );

    const refreshIfStale = async (timeStamp: number) => {
        if (timeStamp < Date.now()) {
            // refresh the page
            await fetchAndStoreUserData(userData, dispatch);

            // then create new time
            const nextRefreshFromStorage = Date.now() + hoursToDelayDefault;
            SaveStorage(
                nextRefreshFromStorage.toString(),
                'updateUserDataTime'
            );
            return nextRefreshFromStorage;
        }

        return timeStamp;
    };

    const obtainRefreshFromLocalStorage = () => {
        let nextRefreshFromStorage: string | null =
            localStorage.getItem('updateUserDataTime');

        // doesn't exist in localStorage create default
        if (!nextRefreshFromStorage) {
            nextRefreshFromStorage = (
                Date.now() + hoursToDelayDefault
            ).toString();
            SaveStorage(nextRefreshFromStorage, 'updateUserDataTime');
        }

        return nextRefreshFromStorage;
    };

    useEffect(() => {
        const setupRefreshTimer = async () => {
            fetchAndStoreUserData(userData, dispatch);

            setHoursToDelay(null);
            const nextRefreshFromStorage: string =
                obtainRefreshFromLocalStorage();
            let nextRefresh: number = parseInt(nextRefreshFromStorage);
            nextRefresh = await refreshIfStale(nextRefresh);
            setHoursToDelay(nextRefresh - Date.now());
        };

        setupRefreshTimer();
    }, []);

    useEffect(() => {
        if (!hoursToDelay || timer) {
            return;
        }

        const newTimer = setTimeout(async () => {
            // sets to null, so changed later will be picked up for rerender
            setHoursToDelay(null);
            // refresh localStorage data
            await fetchAndStoreUserData(userData, dispatch);
            // TODO send refresh message
            window.location.reload();
            // update next refresh time
            const nextRefreshFromStorage = Date.now() + hoursToDelayDefault;
            SaveStorage(
                nextRefreshFromStorage.toString(),
                'updateUserDataTime'
            );

            setHoursToDelay(nextRefreshFromStorage - Date.now());
            setTimer(null);
        }, hoursToDelay);

        setTimer(newTimer);

        // Cleanup function on unmount
        return () => {
            if (newTimer) {
                const nextRefreshFromStorage = Date.now() + hoursToDelayDefault;
                SaveStorage(
                    nextRefreshFromStorage.toString(),
                    'updateUserDataTime'
                );
                setHoursToDelay(nextRefreshFromStorage - Date.now());

                clearTimeout(newTimer);
                setTimer(null);
            }
        };
    }, [hoursToDelay]);

    return <> {children} </>;
};

export default IdleTimer;
