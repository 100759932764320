import { AppComponentInterface } from './app-component.interface';

export interface AppFunctionListInterface {
    id: string;
    name: string;
    tag: string;
    code: string;
    functionType: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface AppFunctionInterface {
    id: string;
    name: string;
    tag: string;
    code: string;
    functionType: string;
    state: number;
    type?: string;
    createdAt: string;
    updatedAt: string;
    appComponent?: AppComponentInterface;
}

export interface AppFunctionFormCreateInterface {
    name: string;
    code: string;
    functionType: string;
    appComponent?: string;
}

export const AppFunctionFormValues: AppFunctionFormCreateInterface = {
    name: '',
    code: '',
    functionType: ''
};
