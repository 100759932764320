import { useEffect, useRef, useState } from 'react';
import { ApiFilesQr, ApiQrLink, ApiQrLinkById } from '../../../config/service';
//  <--COMPONENTS--> //
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import ButtonBody from '../../../components/button/button';
import FormBuilder from '../../../components/formBuilder/formBuilder';
import QRTableComponent from './qr-table';
//  <--HOOKS--> //
import { useToast } from '../../../config/hooks/useToast';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import { QRFormCreateInterface, QRFormValues } from '../../../interface';
import { useNavigate, useParams } from 'react-router';
import { useAlert } from '../../../config/hooks/useAlert';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
//  <--OTHERS--> //
import { lang } from '../../langs';
import { FormCreateQR } from './form';
import '../../../scss/global/global.scss';

const CreateQR = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageQR: {
                title,
                createTitle,
                editTitle,
                detailTitle,
                adminTitle,
                deleteMessage,
                deleteTitle,
                newTitle,
                downloadTitle,
                copyQRLink
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<QRFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();
    const [updates, setUpdates] = useState<boolean>(false);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
            setReadyForm(false);
        } else {
            setEntity(QRFormValues);
            setReadyForm(true);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiQrLinkById(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        if (status) {
            data[data.type] = data.destination;
            setEntity(data);
            setReadyForm(true);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    /**
     * @description
     * @function Submitfile is a function that is called when the form is submitted and 'type' is pdf or photo
     * @param files is a File array that contains 1 File
     */
    const SubmitFile = async (
        files: File[],
        previousValue
    ): Promise<string> => {
        if (!files.length || !(files[0] instanceof File)) {
            if (previousValue) {
                return previousValue;
            }
            ConfigToast.text = 'No se ha subido ningún archivo';
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
            throw new Error('No valid file provided!');
        }

        const file = files[0]; // Only upload one file
        const formData = new FormData();
        formData.append('file', file, file.name);

        try {
            const { data, status } = await GetData(
                ApiFilesQr,
                HTTP_METHODS.POST,
                formData,
                {},
                true
            );

            if (!status) {
                throw new Error('File upload failed.');
            }
            if (!data.filepathdownload) {
                throw new Error(
                    'File upload succeeded, but no download path provided.'
                );
            }

            return data.filepathdownload;
        } catch (error) {
            // Create a new ConfigToast to avoid mutating the global one directly
            const newConfigToast = {
                ...ConfigToast,
                text: 'Error uploading file. Please try again later.',
                backColor: Colors.Error,
                title: 'Upload Error'
            };
            setConfigToast(newConfigToast);

            // Re-throw the error to handle it at a higher level if needed
            throw new Error(`Upload failed: ${error}`);
        }
    };

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: QRFormCreateInterface) => {
        const valueSend = { ...values };
        if (values.type === 'pdf') {
            if (values.pdf) {
                valueSend.destination = await SubmitFile(
                    values.pdf,
                    values.destination
                );
            }
        } else if (values.type === 'photo') {
            if (values.photo) {
                valueSend.destination = await SubmitFile(
                    values.photo,
                    values.destination
                );
            }
        } else {
            valueSend.destination = values[values.type];
        }
        delete valueSend['external-url'];
        delete valueSend['internal-url'];
        const response = id
            ? await GetData(
                  ApiQrLinkById(entityId),
                  HTTP_METHODS.PATCH,
                  valueSend
              )
            : await GetData(ApiQrLink, HTTP_METHODS.POST, valueSend);
        const { message, data, status } = response;
        if (status) {
            if (data.type === 'pdf' || values.type === 'photo') {
                data[data.type] = data.destination;
            }

            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            // // show details of item
            setReadyForm(true);
            setUpdates(true);
            setEntity(data);
            setDetailPage(true);
            setEntityId(data.id);
            navigate(`/panel/qr/edit/${data.id}`);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: React.RefObject<HTMLInputElement>) => {
        if (ref.current) {
            ref.current.focus();
        }
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(ApiQrLinkById(id), HTTP_METHODS.DELETE);
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            navigate('/panel/qr');
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    const download = async () => {
        // Use the Fetch API to get the image
        fetch(entity?.qr_code_url ?? '')
            .then(async response => await response.blob()) // Convert response to Blob
            .then(blob => {
                // Create a URL for the Blob object
                const url = window.URL.createObjectURL(blob);

                // Create a temporary <a> element to trigger the download
                const link = document.createElement('a');
                link.href = url;

                // Set the download attribute with a default file name
                link.download = 'qr_code.png';

                // Append the link to the body
                document.body.appendChild(link);

                // Trigger the download by simulating a click
                link.click();

                // Clean up: remove the link from the document and revoke the URL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error downloading the QR code image:', error);
            });
    };

    const copyLink = async () => {
        if (!entity) {
            return;
        }

        if (entity?.qrDestinationLink) {
            await navigator.clipboard.writeText(
                entity?.qrDestinationLink ?? ''
            );
            ConfigToast.text = 'El enlace se copió al portapapeles';
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
        } else {
            ConfigToast.text =
                'Qr no tiene enlace. Por favor editar qr para copiar.';
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            <ToastElement ref={toastManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/QrIcon.svg' // TODO find QR code icon
                kind='settings'
                option='Qr'
            ></SubNavBar>

            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/qr' }}
                    icon='QrIconB.svg'
                    subtitle={{ name: id ? entity?.name ?? '' : createTitle }}
                />

                <div className='settings__grid-container'>
                    <div
                        className='settings__grid-container__item'
                        id='QrPage/QRList'
                    >
                        <div className='settings__grid-container__item__title title'>
                            {adminTitle}
                        </div>
                        <QRTableComponent refresh={updates} />

                        <div
                            className='settings__grid-container__item__content'
                            id='QrPage/QRList/New'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path='/panel/qr/create'
                            ></ButtonBody>
                        </div>
                    </div>

                    <div
                        className='settings__grid-container__item_right'
                        id='QrPage/QRDetail'
                    >
                        <div className='settings__grid-container__item_right__title title'>
                            {id ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>

                        <div className='settings__grid-container__item_right__content'>
                            <>
                                {readyForm && (
                                    <>
                                        <FormBuilder
                                            initialValues={
                                                entity?.name
                                                    ? entity
                                                    : QRFormValues
                                            }
                                            buttons={FormButtons}
                                            lang={defaultLang}
                                            formStructure={FormCreateQR}
                                            focusOn={fieldRef}
                                            config={{
                                                noClearButton: !!entity?.name,
                                                editButton:
                                                    !!entity?.name && detailPage
                                            }}
                                        />
                                    </>
                                )}
                            </>
                            {id && detailPage && (
                                <>
                                    <div className='settings__grid-container__item_right__content__btn'>
                                        <button
                                            id='QrPage/QRDetail/Delete'
                                            className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                            onClick={Delete}
                                        >
                                            {deleteTitle}
                                        </button>
                                        <br />
                                        <button
                                            id='QrPage/QRDetail/Update'
                                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                            onClick={Edit}
                                        >
                                            {editTitle}
                                        </button>
                                    </div>
                                    <div className='settings__grid-container__item_right__content__btn'>
                                        <button
                                            id='QrPage/QRDetail/Download'
                                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                            onClick={download}
                                        >
                                            {downloadTitle}
                                        </button>
                                        <button
                                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                            onClick={copyLink}
                                        >
                                            {copyQRLink}
                                        </button>
                                    </div>
                                </>
                            )}
                            <br />
                            {readyForm && id && entity?.qr_code_url && (
                                <img
                                    src={entity?.qr_code_url}
                                    alt='QR Code'
                                    className='qrCode'
                                    width='200'
                                    height='200'
                                />
                            )}
                        </div>
                        {LoadingData && <LoaderElement />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateQR;
